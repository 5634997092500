<template>
  <div>
    <NuxtLoadingIndicator color="#F96500" />
    <Header />
    <slot />
    <UtilsLiveChat />
    <Footer />
  </div>
</template>

<script setup>
import { useGlobalStore } from '~/store/global'

const global = useGlobalStore()
const route = useRoute()
const config = useRuntimeConfig()

useHead(() => ({
  link: [
    {
      rel: 'canonical',
      href: `${config.public.BASE_URL}${route.path}`
    }
  ],
  htmlAttrs: {
    lang: 'en'
  }
}))

const SEOMeta = ref({})
const mainBanner = ref({})

const [seo, banner] = await Promise.all([
  useSuitmediaApiData('/api/seo-metas', {
    query: {
      'filters[url]': '/',
      populate: 'seo.image'
    }
  }),
  useSuitmediaApiData(`/api/banners`, {
    query: {
      'filters[url][$eq]': '/home',
      'fields[0]': 'id',
      'populate[banner][fields][0]': 'title',
      'populate[banner][fields][1]': 'description',
      'populate[banner][fields][2]': 'cta_wording',
      'populate[banner][fields][3]': 'cta_url',
      'populate[banner][populate][4]': 'image',
      'populate[banner][populate][5]': 'background_image'
    }
  })
])

SEOMeta.value = seo?.data?.value?.data?.[0] || {}
mainBanner.value = banner?.data?.value?.data?.[0] || {}
global.changeMainSeo(SEOMeta.value)
global.changeMainBanner(mainBanner.value)

// if (window?.HubSpotConversations) window?.HubSpotConversations?.widget?.load()
watch(
  () => route.path,
  () => {
    const hubSotLoaded = window?.HubSpotConversations?.widget?.status().loaded

    if (!hubSotLoaded && route.path !== '/') {
      setTimeout(() => {
        window?.HubSpotConversations?.widget?.load()
      }, 5000)
    }
  }
)
</script>

<style lang="scss"></style>
